//==============================
// Global
//==============================

FastClick.attach(document.body);

/*
$('.element').waypoint(function(direction) {
  var thisElement = $(this.element);
  //stuff
  this.destroy();
}, {
  offset: '50%'
});
*/

$('.video-gallery-item, .post-content p, .wp-block-embed__wrapper, .home-videos-item').fitVids();
